@import 'pasture-pro-theme.scss';

$rust-brown: #BA6128;

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fffdf6;
}

h1, h2, h3, p,
mat-card-title,
mat-card-content,
mat-panel-title,
mat-panel-description,
mat-radio-button,
mat-checkbox,
.mat-mdc-button:not(:disabled),
.mat-mdc-menu-item,
.mdc-expansion-panel {
  color: $dark-text !important;
}

.content {
  text-align: center;
  padding: 30px;
  height: 85%;
}

mat-grid-tile {
  overflow: visible !important;
  .mat-figure {
    flex-direction: column;
    margin-top: 12px !important;
  }

  .mat-grid-tile-content {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &.centered-content .mat-grid-tile-content {
    justify-content: center;
    align-items: center;
  }

  &.radio .mat-grid-tile-content {
    align-items: flex-start;
    padding: 17px;

    mat-label {
      color: #0009;
      margin-bottom: 6px;
    }

    mat-radio-group {
      display: flex;
      justify-content: space-around;
      width: 100%;
    }
  }

  &.chkbx .mat-grid-tile-content {
    justify-content: center;
  }
}

.detail.extra .mat-grid-tile-content {
  justify-content: center;
}

mat-checkbox {
  .mat-mdc-checkbox-touch-target {
    top: 0;
    height: inherit;
    width: inherit;
    transform: unset;
  }

  .mdc-form-field>label {
    padding-left: 15px;
  }
}


login-page {
  text-align: center;
}

.cdk-visually-hidden:not(.mat-datepicker-close-button) {
  display: none !important;
}

.cdk-overlay-container {

  .cdk-overlay-backdrop-showing {
    z-index: 88;
    background-color: #6565658c;
  }

  .cdk-overlay-pane {
    z-index: 99;
  }

  .cdk-global-overlay-wrapper, .cdk-overlay-backdrop-showing {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .cdk-overlay-pane {
    position: relative !important;

    &.error-dialog,
    &.success-dialog,
    &.confirm-dialog,
    &.information-dialog,
    &.email-dialog {
      margin: 250px auto;
    }
    &.form-dialog {
      margin: 50px auto;
    }
    &.bottom-sheet {
      position: absolute !important;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  
  .cdk-overlay-connected-position-bounding-box {
    position: absolute;
    .cdk-overlay-pane {
      position: absolute !important;
      background-color: white;
      right: inherit;

      &:has(.mat-mdc-menu-panel) {
        bottom: 0;
        right: 0;
      }
    }
  }
}

.mat-datepicker-0-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-1-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-2-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-3-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-4-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-5-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-6-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-7-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-8-backdrop + .cdk-overlay-connected-position-bounding-box
{
  width: auto !important; 
}

mat-datepicker-content {
  .mat-calendar-controls button, th {
    color: #5A2C27;
  }
  mat-calendar-table-header-divider {
    border-top: 1px solid rgba(0, 0, 0, .12);
  }
  .mat-calendar-body-cell-content {
    border-color: #5A2C27;
    color: #5A2C27;
  }
  .mat-calendar-body-active {
    .mat-calendar-body-cell-content {
      border-color: #BA6128;
      color: #BA6128;
      font-weight: bold;
      border-width: 2px;
    }
  }

  .mat-datepicker-close-button {
    position: relative !important;
    width: 50%;
    margin: 4px auto;
    background-color: #ffe5c2 !important;
    color: #5A2C27 !important;
    margin-top: 16px !important;
  }
}

mat-dialog-container {
  .mat-mdc-dialog-actions {
    justify-content: end !important;
  }

  .mat-mdc-dialog-surface {
    padding: 16px;
  }
}

dialog-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

mat-bottom-sheet-container {
  color: #5A2C27 !important;
  background-color: white !important;
  a.mdc-list-item {
    padding: 8px;
    text-decoration: underline;
  }
}

app-tutorials {
  .mdc-list-item__content span {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    mat-list {
      width: 100%;
    }
  }
}


mat-form-field {
  width: 90%;
  color: #5A2C27;

  &.colspan {
    width: 95%;
  }
  .mat-mdc-text-field-wrapper {
    height: 40px;
    .mat-mdc-form-field-flex {
      height: 100%;
      align-items: end;
      padding-bottom: 3px;
    }

    .mdc-floating-label {
      top: 35%;
    }
  }
}

mat-button-toggle {
  &.mat-button-toggle-checked {
    color: white;
    background-color: #ba6128;
  }
}


mat-list {
  overflow: auto;
}

animal-form, task-form, calf-form, marker-form, boundary-form {
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a.mdc-tab {
  font-size: 18px;
  color: #b35d28;
  border-bottom: 1px solid;
  opacity: 0.7;

  &.mdc-tab--active {
    opacity: 1;
    font-weight: bold;
  }
}

button {
  &.mdc-fab {
    position: absolute;
    right: 25px;
    bottom: 25px;
    background-color: $rust-brown;
    color: white;
  }
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #fffdf6;
  color: #bf5203;
}

.attachment-tile {
  attachment-list .container {
    columns: 1 !important;
  }
}

section.header-section {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  .action-buttons {
    button {
      margin: 0 8px;
    }
  }
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 13px;
  bottom: 12px;
  left: -50px;
  min-width: 200px;
  padding-right: 20px;
}

.marker-list {
  &>div {
    width: 23% !important;
  }
}

lrp-tabs-page {
  position: relative;
}

watch-list-page {
  display: flex;
  justify-content: center;
}

.grazing-step-2 {
  display: flex;
  img {
    max-width: 50px;
  }
  span {
    padding: 0 16px;
    margin: auto;
  }
}

ngx-charts-gauge {
  .ngx-charts-outer {
    margin: 0 auto;
    position: relative;
  }

  ngx-charts-legend {
    position: absolute;
    left: 0;
    top: 60%;
  }
}

.request-dialog {
  white-space: pre-line;
}

item-picklist {
  .mat-grid-tile-content {
    align-items: center !important;
    color: #5A2C27;
  }
}

.realtedTo {
  .label {
    .mat-grid-tile-content {
      align-items: flex-start;
    }
  }

  .button {
    .mat-grid-tile-content {
      align-items: flex-end;
    }
  }
}

admin-user-ranches-component .buttons .mat-grid-tile-content {
  flex-direction: row;
  justify-content: space-evenly;
}

animal-detail .detail {
  .mat-grid-tile-content {
    align-items: flex-start;
  }

  .button .mat-grid-tile-content {
    align-items: flex-end;
  }
}

//AAP
$aap-green: #949D1F;
$aap-blue: #3C3B6E;
$aap-red: #B12234;

app-top-menu.aap {
  mat-toolbar {
    background-color: $aap-blue !important;
    color: #FFF !important;

    .logo {
      display: none;
    }
  }

  & ~ mat-drawer-container {
    a {
      color: $aap-blue !important;
      &:hover {
        background: #3c3b6e4f !important;
      }
    }
  }
}

aap-tabs-page {

  a.mdc-tab-link {
    color: $aap-blue;
  }

  .mdc-tab-nav-bar .mdc-ink-bar {
    background-color: $aap-blue !important;
  }

  h2, h3 {
    color: $aap-blue !important;
  }

  .gauge-container {
    border-bottom: 1px solid $aap-blue;
    p {
      color: $aap-blue !important;
    }
  }

  mat-form-field.mdc-focused {
    .mdc-form-field-ripple {
      background-color: $aap-blue;
    }
    .mdc-select-arrow, .mdc-form-field-label {
      color: $aap-blue;
    }

  }
}

.aap {
  mat-option {
    span {
      color: $aap-blue;
    }
    &.mdc-selected span {
      color: $aap-green;
      font-weight: bold;
    }

    .mdc-pseudo-checkbox-checked {
      background: $aap-green;
    }
  }
}

prf-tabs-page, aap-tabs-page {
  .prf-content {
    position: relative;
    min-height: 400px;
  }

  .rainfall-container {
    width: 750px;
    margin: 0 auto;
  }

  .mdc-progress-spinner circle, .mdc-spinner circle {
    stroke: $aap-red;
  }

  .prf-table {
    width: 800px;
    margin: 0 auto;
    margin-top: 16px;

    th {
      color: #fff;
      text-transform: uppercase;
      padding: 16px;
    }

    .mdc-footer-cell {
      font-weight: bold;
    }

    .right {
      text-align: right;
    }

    td.mdc-cell, td.mdc-footer-cell {
      padding: 16px;
      font-size: 16px;
      vertical-align: top;
    }
  }

  .predicted-value, .prediction-container {
    color: #007069 !important;
  }

  .total-value {
    font-weight: bold;
  }

}

prf-tabs-page {
  th {
    background-color: #ba6128;
  }
  td.mdc-cell, td.mdc-footer-cell, .total-value {
    color: #5A2C27;
  }
}

aap-tabs-page {
  th {
    background-color: $aap-red;
  }
  td.mdc-cell, td.mdc-footer-cell, .total-value, .total-info {
    color: $aap-blue;
  }

}


.button-container {
  text-align: right;
  padding: 8px 0;

  button {
    margin-right: 8px;
  }
}

.table-container {
  overflow: auto;
  position: relative;

  table {
    width: 100%;
    th {
      font-size: 14px;
      font-weight: 500;
      color: #5a2c27;
    }
    .mat-mdc-row {
      color: #5a2c27;
    }
    tr {
      background: #fffdf6;
      &.detail-row {
        height: 0;
        background: white;
      }

      &.element-row {
        background: #fffdf6;
        cursor: pointer;

        &:not(.expanded-row):hover {
          background: #d9ac8e;
        }
        &:not(.expanded-row):active {
          background: #efefef;
        }

        &.expanded-row {
          background: white;
          font-weight: 500;
        }

        td {
          border-bottom-width: 0;
          padding: 0 8px;
        }

      }

      &.header-filter-row mat-form-field {
        max-width: 99%;
      }
    }
    .footer-row {
      .footer-container {
        display: flex;
        justify-content: space-between;
      }
      td {
        border-top-width: 2px;
        border-top-style: solid;
        border-top-color: #0000001f;
      }
      .filters mat-checkbox {
        margin: 0 8px;
      }
    }
  }
}

table.mdc-table {
  box-shadow: none;

  td {
    text-align: left;
  }
}

mat-paginator {
  background-color: #fffdf6 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #e0ded8;
  border-top: 1px solid #e0ded8;
  .mdc-paginator-container {
    justify-content: flex-start;
  }
}

.details-section {
  position: relative;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffdf6;

  width: 100%;
  top: 0;
  height: 100%;
  z-index: 999;
}

@media only screen and (max-height: 800px) {
  .table-container {
    height: 525px;
  }

  button.mdc-fab {
    right: 15px;
    bottom: 15px;
    z-index: 99;
  }

}

@media only screen and (min-height: 800px) {
  .table-container {
    height: 650px;
  }
}




