.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #b64c00;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #5a2c27;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b64c00;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b64c00;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5a2c27;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5a2c27;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #5a2c27;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #5a2c27;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #f44336;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #b64c00;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #5a2c27;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #b64c00;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #5a2c27;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #b64c00;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #5a2c27;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #b64c00;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #5a2c27;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #b64c00;
  --mat-mdc-button-ripple-color: rgba(182, 76, 0, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #5a2c27;
  --mat-mdc-button-ripple-color: rgba(90, 44, 39, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #f44336;
  --mat-mdc-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background-color: #fffdf6;
}

h1, h2, h3, p,
mat-card-title,
mat-card-content,
mat-panel-title,
mat-panel-description,
mat-radio-button,
mat-checkbox,
.mat-mdc-button:not(:disabled),
.mat-mdc-menu-item,
.mdc-expansion-panel {
  color: #5A2C27 !important;
}

.content {
  text-align: center;
  padding: 30px;
  height: 85%;
}

mat-grid-tile {
  overflow: visible !important;
}
mat-grid-tile .mat-figure {
  flex-direction: column;
  margin-top: 12px !important;
}
mat-grid-tile .mat-grid-tile-content {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
mat-grid-tile.centered-content .mat-grid-tile-content {
  justify-content: center;
  align-items: center;
}
mat-grid-tile.radio .mat-grid-tile-content {
  align-items: flex-start;
  padding: 17px;
}
mat-grid-tile.radio .mat-grid-tile-content mat-label {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 6px;
}
mat-grid-tile.radio .mat-grid-tile-content mat-radio-group {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
mat-grid-tile.chkbx .mat-grid-tile-content {
  justify-content: center;
}

.detail.extra .mat-grid-tile-content {
  justify-content: center;
}

mat-checkbox .mat-mdc-checkbox-touch-target {
  top: 0;
  height: inherit;
  width: inherit;
  transform: unset;
}
mat-checkbox .mdc-form-field > label {
  padding-left: 15px;
}

login-page {
  text-align: center;
}

.cdk-visually-hidden:not(.mat-datepicker-close-button) {
  display: none !important;
}

.cdk-overlay-container .cdk-overlay-backdrop-showing {
  z-index: 88;
  background-color: rgba(101, 101, 101, 0.5490196078);
}
.cdk-overlay-container .cdk-overlay-pane {
  z-index: 99;
}
.cdk-overlay-container .cdk-global-overlay-wrapper, .cdk-overlay-container .cdk-overlay-backdrop-showing {
  position: absolute;
  width: 100%;
  height: 100%;
}
.cdk-overlay-container .cdk-overlay-pane {
  position: relative !important;
}
.cdk-overlay-container .cdk-overlay-pane.error-dialog, .cdk-overlay-container .cdk-overlay-pane.success-dialog, .cdk-overlay-container .cdk-overlay-pane.confirm-dialog, .cdk-overlay-container .cdk-overlay-pane.information-dialog, .cdk-overlay-container .cdk-overlay-pane.email-dialog {
  margin: 250px auto;
}
.cdk-overlay-container .cdk-overlay-pane.form-dialog {
  margin: 50px auto;
}
.cdk-overlay-container .cdk-overlay-pane.bottom-sheet {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box {
  position: absolute;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane {
  position: absolute !important;
  background-color: white;
  right: inherit;
}
.cdk-overlay-container .cdk-overlay-connected-position-bounding-box .cdk-overlay-pane:has(.mat-mdc-menu-panel) {
  bottom: 0;
  right: 0;
}

.mat-datepicker-0-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-1-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-2-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-3-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-4-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-5-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-6-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-7-backdrop + .cdk-overlay-connected-position-bounding-box,
.mat-datepicker-8-backdrop + .cdk-overlay-connected-position-bounding-box {
  width: auto !important;
}

mat-datepicker-content .mat-calendar-controls button, mat-datepicker-content th {
  color: #5A2C27;
}
mat-datepicker-content mat-calendar-table-header-divider {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
mat-datepicker-content .mat-calendar-body-cell-content {
  border-color: #5A2C27;
  color: #5A2C27;
}
mat-datepicker-content .mat-calendar-body-active .mat-calendar-body-cell-content {
  border-color: #BA6128;
  color: #BA6128;
  font-weight: bold;
  border-width: 2px;
}
mat-datepicker-content .mat-datepicker-close-button {
  position: relative !important;
  width: 50%;
  margin: 4px auto;
  background-color: #ffe5c2 !important;
  color: #5A2C27 !important;
  margin-top: 16px !important;
}

mat-dialog-container .mat-mdc-dialog-actions {
  justify-content: end !important;
}
mat-dialog-container .mat-mdc-dialog-surface {
  padding: 16px;
}

dialog-component {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
}

mat-bottom-sheet-container {
  color: #5A2C27 !important;
  background-color: white !important;
}
mat-bottom-sheet-container a.mdc-list-item {
  padding: 8px;
  text-decoration: underline;
}

app-tutorials .mdc-list-item__content span {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
}
app-tutorials .mdc-list-item__content span mat-list {
  width: 100%;
}

mat-form-field {
  width: 90%;
  color: #5A2C27;
}
mat-form-field.colspan {
  width: 95%;
}
mat-form-field .mat-mdc-text-field-wrapper {
  height: 40px;
}
mat-form-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 100%;
  align-items: end;
  padding-bottom: 3px;
}
mat-form-field .mat-mdc-text-field-wrapper .mdc-floating-label {
  top: 35%;
}

mat-button-toggle.mat-button-toggle-checked {
  color: white;
  background-color: #ba6128;
}

mat-list {
  overflow: auto;
}

animal-form, task-form, calf-form, marker-form, boundary-form {
  height: 99%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a.mdc-tab {
  font-size: 18px;
  color: #b35d28;
  border-bottom: 1px solid;
  opacity: 0.7;
}
a.mdc-tab.mdc-tab--active {
  opacity: 1;
  font-weight: bold;
}

button.mdc-fab {
  position: absolute;
  right: 25px;
  bottom: 25px;
  background-color: #BA6128;
  color: white;
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #fffdf6;
  color: #bf5203;
}

.attachment-tile attachment-list .container {
  columns: 1 !important;
}

section.header-section {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
}
section.header-section .action-buttons button {
  margin: 0 8px;
}

.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  padding: 10px;
  font-size: 13px;
  bottom: 12px;
  left: -50px;
  min-width: 200px;
  padding-right: 20px;
}

.marker-list > div {
  width: 23% !important;
}

lrp-tabs-page {
  position: relative;
}

watch-list-page {
  display: flex;
  justify-content: center;
}

.grazing-step-2 {
  display: flex;
}
.grazing-step-2 img {
  max-width: 50px;
}
.grazing-step-2 span {
  padding: 0 16px;
  margin: auto;
}

ngx-charts-gauge .ngx-charts-outer {
  margin: 0 auto;
  position: relative;
}
ngx-charts-gauge ngx-charts-legend {
  position: absolute;
  left: 0;
  top: 60%;
}

.request-dialog {
  white-space: pre-line;
}

item-picklist .mat-grid-tile-content {
  align-items: center !important;
  color: #5A2C27;
}

.realtedTo .label .mat-grid-tile-content {
  align-items: flex-start;
}
.realtedTo .button .mat-grid-tile-content {
  align-items: flex-end;
}

admin-user-ranches-component .buttons .mat-grid-tile-content {
  flex-direction: row;
  justify-content: space-evenly;
}

animal-detail .detail .mat-grid-tile-content {
  align-items: flex-start;
}
animal-detail .detail .button .mat-grid-tile-content {
  align-items: flex-end;
}

app-top-menu.aap mat-toolbar {
  background-color: #3C3B6E !important;
  color: #FFF !important;
}
app-top-menu.aap mat-toolbar .logo {
  display: none;
}
app-top-menu.aap ~ mat-drawer-container a {
  color: #3C3B6E !important;
}
app-top-menu.aap ~ mat-drawer-container a:hover {
  background: rgba(60, 59, 110, 0.3098039216) !important;
}

aap-tabs-page a.mdc-tab-link {
  color: #3C3B6E;
}
aap-tabs-page .mdc-tab-nav-bar .mdc-ink-bar {
  background-color: #3C3B6E !important;
}
aap-tabs-page h2, aap-tabs-page h3 {
  color: #3C3B6E !important;
}
aap-tabs-page .gauge-container {
  border-bottom: 1px solid #3C3B6E;
}
aap-tabs-page .gauge-container p {
  color: #3C3B6E !important;
}
aap-tabs-page mat-form-field.mdc-focused .mdc-form-field-ripple {
  background-color: #3C3B6E;
}
aap-tabs-page mat-form-field.mdc-focused .mdc-select-arrow, aap-tabs-page mat-form-field.mdc-focused .mdc-form-field-label {
  color: #3C3B6E;
}

.aap mat-option span {
  color: #3C3B6E;
}
.aap mat-option.mdc-selected span {
  color: #949D1F;
  font-weight: bold;
}
.aap mat-option .mdc-pseudo-checkbox-checked {
  background: #949D1F;
}

prf-tabs-page .prf-content, aap-tabs-page .prf-content {
  position: relative;
  min-height: 400px;
}
prf-tabs-page .rainfall-container, aap-tabs-page .rainfall-container {
  width: 750px;
  margin: 0 auto;
}
prf-tabs-page .mdc-progress-spinner circle, prf-tabs-page .mdc-spinner circle, aap-tabs-page .mdc-progress-spinner circle, aap-tabs-page .mdc-spinner circle {
  stroke: #B12234;
}
prf-tabs-page .prf-table, aap-tabs-page .prf-table {
  width: 800px;
  margin: 0 auto;
  margin-top: 16px;
}
prf-tabs-page .prf-table th, aap-tabs-page .prf-table th {
  color: #fff;
  text-transform: uppercase;
  padding: 16px;
}
prf-tabs-page .prf-table .mdc-footer-cell, aap-tabs-page .prf-table .mdc-footer-cell {
  font-weight: bold;
}
prf-tabs-page .prf-table .right, aap-tabs-page .prf-table .right {
  text-align: right;
}
prf-tabs-page .prf-table td.mdc-cell, prf-tabs-page .prf-table td.mdc-footer-cell, aap-tabs-page .prf-table td.mdc-cell, aap-tabs-page .prf-table td.mdc-footer-cell {
  padding: 16px;
  font-size: 16px;
  vertical-align: top;
}
prf-tabs-page .predicted-value, prf-tabs-page .prediction-container, aap-tabs-page .predicted-value, aap-tabs-page .prediction-container {
  color: #007069 !important;
}
prf-tabs-page .total-value, aap-tabs-page .total-value {
  font-weight: bold;
}

prf-tabs-page th {
  background-color: #ba6128;
}
prf-tabs-page td.mdc-cell, prf-tabs-page td.mdc-footer-cell, prf-tabs-page .total-value {
  color: #5A2C27;
}

aap-tabs-page th {
  background-color: #B12234;
}
aap-tabs-page td.mdc-cell, aap-tabs-page td.mdc-footer-cell, aap-tabs-page .total-value, aap-tabs-page .total-info {
  color: #3C3B6E;
}

.button-container {
  text-align: right;
  padding: 8px 0;
}
.button-container button {
  margin-right: 8px;
}

.table-container {
  overflow: auto;
  position: relative;
}
.table-container table {
  width: 100%;
}
.table-container table th {
  font-size: 14px;
  font-weight: 500;
  color: #5a2c27;
}
.table-container table .mat-mdc-row {
  color: #5a2c27;
}
.table-container table tr {
  background: #fffdf6;
}
.table-container table tr.detail-row {
  height: 0;
  background: white;
}
.table-container table tr.element-row {
  background: #fffdf6;
  cursor: pointer;
}
.table-container table tr.element-row:not(.expanded-row):hover {
  background: #d9ac8e;
}
.table-container table tr.element-row:not(.expanded-row):active {
  background: #efefef;
}
.table-container table tr.element-row.expanded-row {
  background: white;
  font-weight: 500;
}
.table-container table tr.element-row td {
  border-bottom-width: 0;
  padding: 0 8px;
}
.table-container table tr.header-filter-row mat-form-field {
  max-width: 99%;
}
.table-container table .footer-row .footer-container {
  display: flex;
  justify-content: space-between;
}
.table-container table .footer-row td {
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.1215686275);
}
.table-container table .footer-row .filters mat-checkbox {
  margin: 0 8px;
}

table.mdc-table {
  box-shadow: none;
}
table.mdc-table td {
  text-align: left;
}

mat-paginator {
  background-color: #fffdf6 !important;
  font-size: 14px !important;
  border-bottom: 1px solid #e0ded8;
  border-top: 1px solid #e0ded8;
}
mat-paginator .mdc-paginator-container {
  justify-content: flex-start;
}

.details-section {
  position: relative;
}

.spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fffdf6;
  width: 100%;
  top: 0;
  height: 100%;
  z-index: 999;
}

@media only screen and (max-height: 800px) {
  .table-container {
    height: 525px;
  }
  button.mdc-fab {
    right: 15px;
    bottom: 15px;
    z-index: 99;
  }
}
@media only screen and (min-height: 800px) {
  .table-container {
    height: 650px;
  }
}